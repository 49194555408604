<template>
  <Header
    v-bind="backLink"
    isWide
  >
    <template v-slot:service>
      <router-link
        :is="themeSettings.eventCode ? 'div' : 'router-link'"
        :to="!themeSettings.eventCode && $root.getRootRoute()"
      >
        <img src="@/assets/img/live-logo.svg" alt="">
      </router-link>

      <slot name="after-live-badge"></slot>
    </template>
    <template v-slot:title v-if="event.name">
      <div class="text-ellipsis" v-if="themeSettings.eventCode">
        <b>{{ event.name }}</b>
      </div>
      <Link
        v-else
        v-show="event.name"
        class="rr-link_ellipsis"
        :href="`${$env.RussiaRunning}/event/${event.code || event.id}`"
        :text="`<span class='color-text'>${event.name}</span>`"
        variant="secondary"
        size="md"
        icon-right="externa-link"
        target="_blank"
      ></Link>
    </template>
    <template v-slot:right>
      <router-link
        v-if="$store.getters.visibiltyFollowMemberAvailable"
        :to="$root.getRoute({ name: 'Follow', params: { eventCode } })"
      >
        <Button
          shape="square"
          variant="clear"
          icon-size="xl"
          icon-right="star-empty"
          :class="{ 'transparent': !event.name }"
        >
          <div class="follow-badge" v-show="eventFollowCount">
            {{ eventFollowCount > 99 ? '99+' : eventFollowCount }}
          </div>
        </Button>
      </router-link>
    </template>
  </Header>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header/HeaderExternalBack.vue';

export default {
  components: {
    Header,
  },
  props: {
    event: Object,
    eventCode: String,
    broadcasts: Array,
  },
  computed: {
    ...mapGetters('settings', ['themeSettings']),
    ...mapGetters('follow', ['followList']),
    backLink() {
      return this.themeSettings.mainSite
        ? { backHref: this.themeSettings.mainSite }
        : { backTo: '/' };
    },
    eventFollowCount() {
      return this.broadcasts.reduce((count, broadcast) => {
        const key = `follow_${this.event.code}_${broadcast.RaceId}`;
        return count + (this.followList[key] || []).length;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  bottom: 100%;
  left: 100%;
  margin: -19px;
  background-color: $color-danger;
  color: $color-white;
  border-radius: 99px;
  height: 16px;
  min-width: 16px;
  font-size: 11px;
  padding: 0 4px;
  line-height: 0;

  + .rr-button__icon {
    margin-left: 0 !important;
  }
}
</style>
