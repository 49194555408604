<template>
  <main class="broadcast-page layout__main">
    <component
      :is="currentTenantHeader"
      :event="event"
      :eventCode="eventCode"
      :broadcasts="broadcasts"
    />

    <div class="rr-header-offset"></div>
    <div class="rr-header-bottom-offset" v-if="event.name"></div>
    <Loader v-if="isLoading"></Loader>
    <template v-else-if="isFinished">
      <section class="section_pt section_pb">
        <div class="container">
          <Row justify="center">
            <Col :size="{ lg: '20' }">
              <div class="container__mobile">
                <div class="block block_wide">
                  <EmptyState
                    :img="themeImages['finish-line']"
                    :title="$t('broadcast.finished_title')"
                    :subtitle="$t('broadcast.finished_subtitle')"
                  >
                    <div class="section__group">
                      <Button
                        variant="primary"
                        :text="$t('broadcast.see_results')"
                        size="lg"
                        :href="`${$env.Results}/event/${eventCode}/results`"
                      ></Button>
                    </div>
                  </EmptyState>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </template>
    <template v-else>
      <BroadcastIntro
        ref="intro"
        :weather="eventsWeather[event.code]"
        :location="event.location"
        :timezone="event.timezone"
      >
        <BroadcastSearch
          :raceId="broadcast.RaceId"
          :chronoId="broadcast.ChronometrageId"
          :pageSize="broadcast.ResultsPageSize"
          :races="broadcasts"
          @findResult="findResult"
        ></BroadcastSearch>
      </BroadcastIntro>
      <Tabs
        :items="navOptions"
        variant="white"
        centered
        style="box-shadow: none; overflow-x: auto;"
        v-show="navOptions.length > 1"
      ></Tabs>
      <router-view
        ref="view"
        v-if="!isLoading"
        :eventCode="eventCode"
        :event="event"
        :broadcastCodeProp="broadcastCode"
        @changeBroadcastCode="broadcastCode = $event"
        :broadcast="broadcast"
      ></router-view>
    </template>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import { getDiscipline } from '@/utils';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import Header from '@/tenants/default/Header/HeaderBack.vue';
import Tabs from '../../components/Tabs/Tabs.vue';
import BroadcastIntro from '../../components/BroadcastIntro/BroadcastIntro.vue';
import BroadcastSearch from '../../components/BroadcastSearch/BroadcastSearch.vue';

export default {
  name: 'Broadcast',
  components: {
    Header,
    EmptyState,
    Tabs,
    BroadcastIntro,
    BroadcastSearch,
  },
  props: {
    event: Object,
    eventsLoading: Boolean,
  },
  data() {
    return {
      broadcastCode: null,
      broadcasts: [],
      filters: {},
      isLoading: true,
      isFinished: false,
    };
  },
  computed: {
    ...mapGetters('broadcasts', ['eventsWeather']),
    ...mapGetters('settings', ['themeImages', 'themeSettings']),
    currentTenantHeader() {
      const tenants = {};

      return tenants[this.theme] || Header;
    },
    isDirectLink() {
      return this.$route.meta.isDirectLink;
    },
    eventCode() {
      return this.isDirectLink
        ? this.$route.params.eventCode
        : this.$route.query.code;
    },
    broadcast() {
      if (!this.broadcastCode) {
        return {};
      }
      return this.broadcasts.find((o) => o.RaceCode === this.broadcastCode);
    },
    navOptions() {
      const broadcastRoute = this.$root.getBroadcastRoute(this.eventCode, this.broadcastCode);
      const config = {
        params: broadcastRoute.params,
        query: broadcastRoute.query,
      };

      return [
        {
          id: 'participants',
          name: this.$t('broadcast.participants'),
          to: broadcastRoute,
        },
        {
          id: 'leaders',
          name: this.$t('broadcast.leaders'),
          to: this.$root.getRoute({ name: 'Leaders', ...config }),
          notAvailable: !this.broadcasts.some((o) => o.ShowLeaders
            && (!o.IsRelay || o.HasCategories)),
        },
        {
          id: 'statistics',
          name: this.$t('broadcast.statistics'),
          to: this.$root.getRoute({ name: 'Statistics', ...config }),
        },
      ].filter((o) => !o.notAvailable);
    },
  },
  methods: {
    findResult({ id, raceCode, page }) {
      const data = {
        id,
        page,
        raceCode: this.broadcastCode !== raceCode ? raceCode : undefined,
      };
      const scrollPosition = window.scrollY;

      this.$router.replace(this.$root.getBroadcastRoute(this.eventCode, raceCode))
        .then(() => { window.scrollTo(0, scrollPosition); })
        .catch(() => {})
        .finally(() => {
          this.broadcastCode = raceCode;
          this.$refs.view.findResult(data);
        });
    },
    setData() {
      this.isFinished = !this.eventsLoading && !this.event.name;
      this.isLoading = this.eventsLoading;
      if (!this.event.name) {
        return;
      }
      this.$store.dispatch('broadcasts/updateServerUrl', {
        eventCode: this.eventCode,
      });
      this.broadcasts = this.event.broadcasts
        .map((o) => ({
          ...o,
          disciplineIcon: getDiscipline(o.DisciplineCode)?.icon,
        }));
      this.setBroadcastCode();
    },
    setBroadcastCode() {
      const queryCode = this.isDirectLink ? this.$route.params.raceCode : this.$route.query.race;
      const queryCodeValid = queryCode && this.broadcasts.find((o) => o.RaceCode === queryCode);
      this.broadcastCode = queryCodeValid ? queryCode : this.broadcasts[0].RaceCode;
    },
    setEventImg() {
      const customImg = this.themeImages['custom-event-bg'];

      if (customImg) {
        setTimeout(() => this.$refs.intro?.setImage(customImg));
        return;
      }

      const eventImg = this.event.headerBanner && this.event.headerBanner.replace('-x-', '-x1000');

      let disciplineImg = getDiscipline(this.event.disciplineCode)?.imgLg
        || getDiscipline('run').imgLg;
      disciplineImg = `${this.$env.RussiaRunning}/${disciplineImg}`;

      const img = new Image();

      img.onload = () => {
        this.$refs.intro.setImage(img.src);
      };
      img.onerror = () => {
        if (img.src === disciplineImg) {
          return;
        }
        img.src = disciplineImg;
      };
      img.src = eventImg || disciplineImg;
    },
  },
  mounted() {
    this.setData();
  },
  watch: {
    broadcastCode(val) {
      const config = this.isDirectLink
        ? {
          params: {
            ...this.$route.params,
            raceCode: val,
          },
          query: this.$route.query,
        }
        : {
          query: {
            ...this.$route.query,
            race: val,
          },
        };
      this.$router.replace(config).catch(() => {});
    },
    isLoading(val) {
      if (!val && !this.isFinished) {
        this.setEventImg();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./Broadcast";
</style>
