<template>
  <div class="layout" id="app">
    <Icons :icons-context="iconsContext"></Icons>
    <template v-if="themeLoaded">
      <AdminBar
        v-if="showAdminBar"
      ></AdminBar>
      <router-view></router-view>
      <div class="notifications">
        <div class="rr-header-offset"></div>
      </div>
      <component v-if="!$isFullscreen" :is="currentTenantFooter" />
      <Alerts></Alerts>
    </template>
    <CookieGDPR></CookieGDPR>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Icons from '@rr-component-library/icons/src/main';
import AdminBar from '@/components/AdminBar/AdminBar.vue';
import Footer from '@/tenants/default/Footer/Footer.vue';
import Alerts from '@/components/Alerts/Alerts.vue';
import CookieGDPR from '@/components/CookieGDPR/CookieGDPR.vue';

export default {
  name: 'Live',
  components: {
    AdminBar,
    Icons,
    Footer,
    Alerts,
    CookieGDPR,
  },
  data() {
    return {
      sizes: {
        isMobile: false,
        isTablet: false,
        isDesktop: false,
      },
      ready: false,
      iconsContext: require.context(
        '!svg-inline-loader?'
        + 'removeTags=true&removeSVGTagAttrs=true'
        + '!../../../src/assets/icons',
        false,
        /\w+\.svg$/i,
      ),
      showAdminBar: window.userInfo && (
        window.userInfo.IsAdmin
        || window.userInfo.IsJudge
        || window.userInfo.IsBeneficiary
      ),
    };
  },
  provide() {
    return {
      sizes: this.sizes,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeLoaded', 'themeSettings']),
    currentTenantFooter() {
      const tenants = {
        pionercup: () => import('@/tenants/pionercup/Footer/Footer.vue'),
        legkovrace: () => import('@/tenants/legkovrace/Footer.vue'),
        tula: () => import('@/tenants/tula/Footer.vue'),
        samarkand: () => import('@/tenants/samarkand/Footer.vue'),
      };

      return tenants[this.theme] || Footer;
    },
  },
  methods: {
    setSizes() {
      this.sizes.isMobile = window.innerWidth < 768;
      this.sizes.isTablet = window.innerWidth >= 768 && window.innerWidth < 1024;
      this.sizes.isDesktop = window.innerWidth >= 1024;
    },
    ...mapActions('settings', ['setupTheme']),
    getBroadcastRoute(eventCode, raceCode) {
      return this.$route.meta.isDirectLink
        ? {
          name: 'Broadcast_direct',
          params: { eventCode, raceCode },
        }
        : {
          name: 'Broadcast',
          query: {
            code: eventCode,
            race: raceCode,
          },
        };
    },
    getRootRoute() {
      return this.getBroadcastRoute(this.themeSettings.eventCode);
    },
    getRoute(to) {
      if (!this.$route.meta.isDirectLink) {
        return to;
      }

      const resolved = this.$router.resolve(to)?.resolved;
      if (resolved?.meta.directName) {
        return this.$router.resolve({
          name: resolved.meta.directName,
          params: resolved.params,
          query: resolved.query,
        })?.resolved || to;
      }

      return to;
    },
  },
  beforeCreate() {
    window.setThemeSettings = () => this.setupTheme();
  },
  created() {
    this.$root.getRoute = this.getRoute;
    this.$root.getBroadcastRoute = this.getBroadcastRoute;
    this.$root.getRootRoute = this.getRootRoute;
  },
  mounted() {
    this.setSizes();
    window.addEventListener('resize', this.setSizes);
  },
  unmounted() {
    window.removeEventListener('resize', this.setSizes);
  },
};
</script>

<style lang="scss">
@import "./App";
</style>
