<template>
  <Header
    isWide
  >
    <template v-slot:name>
      <img src="@/assets/img/live-logo.svg" alt="">

      <slot name="after-live-badge"></slot>
    </template>
  </Header>
</template>

<script>
import Header from '@/components/Header/HeaderExternal.vue';

export default {
  components: {
    Header,
  },
};
</script>
